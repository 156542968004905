export const getClientRelativeDate = (date: Date | string | number): Date => {
  const clientDate = new Date(date);
  const clientOffset = clientDate.getTimezoneOffset();
  const clientOffsetMs = clientOffset * 60 * 1000;
  const clientTime = clientDate.getTime();
  const clientTimeMs = clientTime - clientOffsetMs;
  return new Date(clientTimeMs);
};

export const isValidDateString = (dateStr: string): boolean => {
  const date = new Date(dateStr);
  const timestamp = date.getTime();

  // Validate the Date
  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  // Validate the string
  if (date.toISOString().startsWith(dateStr)) {
    return true;
  }

  return false;
};

export const dateIsValid = (dateStr: string): boolean => {
  // full regex for what they want to support but don't currently.
  // const regex = /(^\d{4}-|\/\d{2}-|\/\d{2}$)|(^\d{6}$)|(^\d{2}-\d{2}-\d{4}$)|(^\w{3}-(\d{2}|\d{4})$)/gm;

  // some xlsx files can autoformat years as numbers eg 2017 => 2,017 so this removes the commas
  // See makeUploadReportReqBody in lib/utils/report-helpers.ts to see how the period value is cleaned
  // before being passed to the submit
  const str = dateStr.replaceAll(',', '');

  // handles 2019-09-12 and 2019
  const regex = /(^\d{4}-\d{2}-\d{2}$)|(^\d{4}$)/gm;

  const isValid = regex.test(str);
  // Validate the string
  if (!isValid) {
    return false;
  }

  const date = new Date(str);

  const timestamp = date.getTime();

  // Validate the Date
  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  // eg const str = '2022-01-02'
  // eg const d = new Date(str)
  // d.toISOString() => 2022-01-02T00:00:00.000Z
  return date.toISOString().startsWith(str);
};

export const getDateNDaysFromNow = (n: number): Date => {
  const today = new Date();
  const nDate = new Date(today);
  nDate.setDate(nDate.getDate() - n);

  return nDate;
};

// new function made from previous one in jsx to reuse with last account date
export const formatReportSummaryDate = (date: string | number): string => {
  // So apparently this renders Jun 20, 2006 on Node 12 but 20 jun 2006 on Node 15.
  // Will disable the test for now but good to know (issue picked up by Github Actions)
  return new Date(date).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};
